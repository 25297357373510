import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { Link } from 'gatsby';

const SubNav = () => (
  <div className="bg-secondary--2 p-2 uppercase text-base hidden lg:block">
    <nav>
      <ul className="flex">
        <li>
          <Link className="px-7  py-2 border-secondary border-r-2" to="#about">
            About
          </Link>
        </li>
        <li>
          <Link className="px-7  py-2 border-secondary border-r-2" to="#vision">
            Vision
          </Link>
        </li>
        <li>
          <Link className="px-7  py-2 border-secondary border-r-2" to="#team">
            Team
          </Link>
        </li>
        <li>
          <Link className="px-7 py-2 " to="/case-studies/one/">
            Case studies
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);

const Company = () => (
  <Layout subNav={SubNav}>
    <SEO
      title="Tail Spend Experts"
      description="Maistro provides source to pay solutions for gaining visibility over unmanaged spend"
    />
    <main className="font-light">
      <div className="container" id="about">
        <article>
          <div className="flex  flex-col-reverse lg:flex-row mb-8">
            <div className="lg:w-1/2">
              <h1 className="font-normal uppercase text-2xl text-black mb-2 leading-tight">
                About
              </h1>
              <p className="text-4xl md:text-5xl font-light text-primary leading-tight">
                Maistro is a software service provider harnessing cutting edge
                technology and data analytics to lead the way in tail spend
                management
              </p>
            </div>
            <div className="lg:w-1/2 py-8 lg:pr-0 lg:pl-6">
              <img src="/images/platform-laptop.png" alt="" />
            </div>
          </div>
          <h5>Why</h5>
          <div className="md:flex mb-16 text-primary ">
            <div className="md:w-1/2 md:pr-8">
              <p>
                We believe it’s time to interrogate the small spend. Why?
                Because small contracts account for about 20% of all business
                spending. Typically, these are business services – IT,
                facilities, marketing, professional services, travel, HR –
                procured by colleagues across a firm.
              </p>
            </div>
            <div className="md:w-1/2 md:pr-8">
              <p>
                Right now, all eyes are on cost management. Businesses need a
                better way to buy services. Often, it’s the tail spend that goes
                unchecked. Yet, it’s the key to significant savings and
                efficiencies. This is where Maistro comes in.{' '}
              </p>
            </div>
          </div>
          <h5>How</h5>
          <div className="md:flex mb-16 text-primary ">
            <div className="md:w-1/2 md:pr-8">
              <p>
                Maistro is the future of tail spend management. We bring
                time-saving automation and AI-powered insight together into a
                precise procurement platform.
              </p>
            </div>
            <div className="md:w-1/2 md:pr-8">
              <p>
                Designed for volume, it’s a unique combination of{' '}
                <strong>analytics</strong>, <strong>sourcing</strong> and{' '}
                <strong>contracting</strong>. Levelling the playing field for
                suppliers and improving outcomes for clients.
              </p>
            </div>
          </div>
        </article>
        <div className="p-8 bg-secondary--2 text-center mb-24">
          <h1 className="text-3xl md:text-5xl leading-snug ">
            We’re transforming how companies buy business services, delivering
            control and visibility at scale.
          </h1>
        </div>
      </div>
      <div
        id="vision"
        className="bg-primary text-white relative overflow-hidden"
      >
        <img
          src="/images/Vision-Right-Image.png"
          alt=""
          className="md:absolute inset-0 left-auto md:w-1/2 md:h-full md:object-cover"
        />
        <div className="container  flex flex-col justify-center relative">
          <div className="max-w-md py-14 relative z-10 text-primary">
            <h5 className="mb-8 text-white">Our vision</h5>

            <h1 className="text-3xl md:text-5xl leading-snug text-white max-w-2xl">
              Our vision is a future where technology and data makes buying and
              selling business services fast, fair and sustainable for all
            </h1>
          </div>
        </div>
      </div>
      <div className="container pt-16 pb-24">
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-primary--3 text-primary px-8 py-10">
            <h5 className="mb-8">OUR MISSION</h5>
            <p>
              To become essential to our customers by providing differentiated
              products and services to help them achieve their aspirations
            </p>
          </div>
          <div className="bg-primary--3 text-primary px-8 py-10">
            <h5 className="mb-8">OUR DIFFERENCE</h5>
            <p>
              We empower our customers to make better buying decisions with our
              user-friendly sourcing portal, AI-powered data analytics and
              pre-vetted supplier network.
            </p>
          </div>
          <div className="bg-primary--3 text-primary px-8 py-10">
            <h5 className="mb-8">OUR VALUES</h5>
            <p className="mb-8">
              Our work is guided and informed by our beliefs and commitments to
              inclusiveness - we respect people, value diversity and are
              committed to equality.
            </p>
          </div>
        </div>
        <div className="mt-16 ">
          <img
            src="/images/Asset_21@2x.png"
            alt=""
            className="w-full hidden sm:block"
          />
          {/* <img
            src="/images/about-divider

          .png"
            alt=""
            className="w-full  sm:hidden"
          /> */}
        </div>
      </div>
    </main>
  </Layout>
);

export default Company;
